import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "source" ]

  connect() {
    this.isPassword = this.sourceTarget.getAttribute("type") === "password"
    this.supported = false
    if (document.queryCommandSupported("copy")) {
      console.log("Copy supported")
      this.supported = true
      // this.element.querySelector(".hidden").classList.remove("hidden")
    }
  }

  copy (e) {
    if (!this.supported) {
      return
    }

    if (this.isPassword) {
      this.sourceTarget.setAttribute("type", "text")
    }

    if (this.sourceTarget.select) {
      // input or supports select()
      console.log("input copy")
      this.sourceTarget.select()
      document.execCommand("copy")
      this.sourceTarget.blur()
    } else {
      // plain text
      console.log("plain text copy")
      var CopyRange = document.createRange();
      CopyRange.selectNode(this.sourceTarget);
      window.getSelection().addRange(CopyRange);
      document.execCommand("copy")
      window.getSelection().removeRange(CopyRange);
    }

    if (this.isPassword) {
      this.sourceTarget.setAttribute("type", "password")
    }
  }
}
