import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "submenu", "button" ]

  connect () {
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.nevermind = this.nevermind.bind(this)
    this.hideMenus()
    this.buttonTarget.addEventListener("click", this.show)
  }

  hideMenus() {
    this.submenuTargets.forEach((submenu) => {
      submenu.classList.add("hidden")
    })
  }

  show(e) {
    e.preventDefault()

    const submenu = this.submenuTarget

    submenu.classList.remove("hidden")
    submenu.addEventListener("mouseleave", this.closeMenu)
    submenu.addEventListener("mouseenter", this.nevermind)

    this.openMenu = submenu
    this.closeMenu(null, 1000)
  }

  closeMenu(_e, timeout = 500) {
    this.closeTimeout = setTimeout(this.hide, timeout)
  }

  nevermind() {
    clearTimeout(this.closeTimeout)
  }

  hide(e) {
    const submenu = this.submenuTarget

    submenu.classList.add("hidden")
    submenu.removeEventListener("mouseleave", this.closeMenu)
    submenu.removeEventListener("mouseenter", this.nevermind)
  }
}
