import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.scroll = this.scroll.bind(this)
    const config = { childList: true }

    this.observer = new MutationObserver(this.scroll)
    this.observer.observe(this.element, config)
  }

  disconnect () {
    this.observer.disconnect()
  }

  scroll (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const children = this.element.children
        children[children.length - 1].scrollIntoView()
      }
    }
  }
}
