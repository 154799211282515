/* eslint no-console:0 */

import 'tailwindcss/tailwind.css'
import '../css/application.css'

// import 'channels'
import 'controllers'

import $ from 'jquery'

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
// import { Turbo } from '@hotwired/turbo-rails' // add `cable` here when broadcasting
import '@hotwired/turbo-rails'

// require('@rails/activestorage').start()

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'

$(document).on('turbo:load', () => console.log('TURBO'))

window.$ = window.jQuery = $

require('@rails/ujs').start()
